import { inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { env } from 'process';
import { environment } from 'apps/senseware-iot/src/environments/environment';
import { DataService } from '../../shared/services/data.service';

@Injectable({
  providedIn: 'root',
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    public dataService: DataService,
    public authService: AuthService,
    public router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authService = inject(AuthService);
    if (
      req.url.search(environment.settings.sensewareApiHost) > -1 &&
      req.url.search('/login') === -1 &&
      !this.router.url.includes('/validate')
    ) {
      // console.log(req)
      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          if (
            req.responseType === 'blob' &&
            error.error instanceof Blob &&
            error.error.type === 'application/json'
          ) {
            // Convert Blob error response to JSON
            return new Observable<HttpEvent<any>>((observer) => {
              const reader = new FileReader();
              reader.onload = (e: Event) => {
                const errorText = (e.target as FileReader).result as string;
                const errorJson = JSON.parse(errorText);
                error = new HttpErrorResponse({
                  error: errorJson,
                  headers: error.headers,
                  status: error.status,
                  statusText: error.statusText,
                  url: error.url,
                });
                this.handleError(error, authService);
                observer.error(error);
                observer.complete();
              };
              reader.onerror = (e: Event) => {
                observer.error(error);
                observer.complete();
              };
              reader.readAsText(error.error);
            });
          } else {
            this.handleError(error, authService);
            return throwError(error);
          }
        })
      );
    } else {
      return next.handle(req);
    }
  }

  private handleError(
    error: HttpErrorResponse,
    authService: AuthService
  ): void {
    if (error.status === 423) {
      if (error.error.message === 'password_expired') {
        this.dataService.showError('Password Expired');
        setTimeout(() => {
          authService.clearStorage();
          window.open(
            `${environment.settings.AVL_URL}/auth/expiry/${error.error.errors.username}`,
            '_blank'
          );
          this.router.navigate(['/auth/login'], { replaceUrl: true });
          return;
        }, 300);
      }
    } else if (error?.status == 401) {
      this.dataService.showError('Unauthorized');
      setTimeout(() => {
        authService.clearStorage();
        this.router.navigate(['/auth/login'], { replaceUrl: true });
        return;
      }, 300);
    } else if (error?.status == 403) {
      this.dataService.showError('Forbidden');
    }
  }
}
