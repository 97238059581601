import { Injectable } from '@angular/core';
import { environment } from 'apps/senseware-iot/src/environments/environment';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  forkJoin,
  interval,
  timer,
} from 'rxjs';
import { map } from 'rxjs/operators';
import { roundDownDecimals } from '../helpers/roundDownDecimals';
import {
  AggregatedSensor,
  AggregatedSensorTypes,
  AggregatedSensorUnits,
  AnimationState,
  DirectSensor,
  DirectSensorUnits,
  Median,
  Room,
  RoomInterval,
  RoomSensorTypeInterval,
  Sensor,
  SensorType,
  SocketMessageBody,
  SocketMessageParam,
  Unit,
  Warehouse,
} from '../interfaces/interfaces';
import { CalibrateService } from './calibrate.service';
import { HttpService } from './http.service';
import { TokenService } from './token.service';
import dayjs, { Dayjs } from 'dayjs';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SENSOR_NAMES } from '../models/sensor-types';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  mainDataMap: Map<string, Warehouse> = new Map<string, Warehouse>();
  filteredDataMap: Map<string, Warehouse> = new Map<string, Warehouse>();

  visibleWarehousesCount: number = 0;

  warehouses: Array<any> = [];
  rooms: Array<any> = [];
  units: Array<any> = [];
  allRoomsUnits: Array<any> = []; //

  warehousesRequestsCount = 1;
  roomsRequestsCount = 1;
  unitsRequestsCount = 1;

  warehousesLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  roomsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  unitsLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  mainDataMapLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  latestSocketMessageUpdate: Subject<any> = new Subject();
  roomIntervalUpdateList: BehaviorSubject<any> = new BehaviorSubject({
    listUpdated: false,
    id: null,
  });
  settingsUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  // roomLatestSocketUpdate: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  socketUpdate: Subject<Unit> = new Subject();

  allDataLoaded: Observable<boolean> = combineLatest([
    this.warehousesLoaded,
    this.roomsLoaded,
    this.unitsLoaded,
  ]).pipe(
    // Check if all values are true
    map(([warehouses, rooms, units]) => warehouses && rooms && units)
  );

  unitsIds: Array<string> = [];

  offlinePeriod = 60 * 60 * 1000;
  // offlinePeriod = 10000;

  valueChangeState: AnimationState = {};
  loading: boolean = true;

  warehousesSelectedSort = ''; // This will hold the selected city object
  warehousesSearchModel: any = '';

  roomsSelectedSort = ''; // This will hold the selected city object
  roomsSearchModel: any = '';

  sortModel = {
    appliedSort: '',
    sortKey: '',
  };
  initialTimestamp: EpochTimeStamp;
  currentDayjs: Dayjs = dayjs();
  currentDayjsTimer: Observable<any> = interval(1000);
  currentDayjs$: Observable<any>;
  userTimeZone: string;
  constructor(
    private tokenSevice: TokenService,
    private httpService: HttpService,
    private calibrateService: CalibrateService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    // this.loadAllData();
  }

  warehousesRequest: Observable<any> = this.getWarehouses().pipe(
    map((warehouseResponse: any) => {
      this.warehouses = [...this.warehouses, ...warehouseResponse.data];
      this.warehousesRequestsCount = this.setRequestPagesCount(
        warehouseResponse.meta.pagination
      );
      if (this.warehousesRequestsCount > 1) {
        const requests: Array<Observable<any>> = [];
        for (var i = 1; i < this.warehousesRequestsCount; i++) {
          requests.push(this.getWarehouses(i + 1));
        }
        forkJoin(requests)
          .pipe(
            map((responses, index) => {
              responses.map((response) => {
                this.warehouses = [...this.warehouses, ...response.data];
              });
              this.warehousesLoaded.next(true);
            })
          )
          .subscribe();
      } else {
        this.warehousesLoaded.next(true);
      }
    })
  );

  roomsRequest: Observable<any> = this.getRooms().pipe(
    map((roomsResponse: any) => {
      this.rooms = [...this.rooms, ...roomsResponse.data];
      this.roomsRequestsCount = this.setRequestPagesCount(
        roomsResponse.meta.pagination
      );
      if (this.roomsRequestsCount > 1) {
        const requests: Array<Observable<any>> = [];
        for (var i = 1; i < this.roomsRequestsCount; i++) {
          requests.push(this.getRooms(i + 1));
        }
        forkJoin(requests)
          .pipe(
            map((responses, index) => {
              responses.map((response) => {
                this.rooms = [...this.rooms, ...response.data];
              });
              this.roomsLoaded.next(true);
            })
          )
          .subscribe();
      } else {
        this.roomsLoaded.next(true);
      }
    })
  );

  unitsRequest: Observable<any> = this.getUnits().pipe(
    map((unitsResponse: any) => {
      this.unitsRequestsCount = this.setRequestPagesCount(
        unitsResponse.meta.pagination
      );
      this.units = [...this.units, ...unitsResponse.data];
      if (this.unitsRequestsCount > 1) {
        const requests: Array<Observable<any>> = [];
        for (var i = 1; i < this.unitsRequestsCount; i++) {
          requests.push(this.getUnits(i + 1));
        }
        forkJoin(requests)
          .pipe(
            map((responses, index) => {
              responses.map((response) => {
                this.units = [...this.units, ...response.data];
              });
              this.unitsLoaded.next(true);
            })
          )
          .subscribe();
      } else {
        this.unitsLoaded.next(true);
      }
    })
  );

  /**
   * storing units that's assigned to rooms to refactor the api calling of all units
   * to hit only needed units with filtration when finished on the backend
   *
   * will also need these id's combined to start a socket subscription with them
   */

  loadAllData() {
    this.warehousesRequest.subscribe();
    this.roomsRequest.subscribe();
    this.unitsRequest.subscribe();
    this.allDataLoaded.subscribe((loaded) => {
      if (loaded) {
        // console.log('loadAllData', loaded);
        this.warehouseDataRefactor();
        this.roomsDataRefactor();

        this.filteredDataMap = this.setMapSortOrder(
          this.filteredDataMap,
          this.sortModel.appliedSort,
          this.sortModel.sortKey
        );

        this.loading = false;
      }
    });
  }

  /**
   * WIP: to paginate requests based on how many items and limit on each request
   */
  setRequestPagesCount(pagination): number {
    const pagesCount = pagination?.perPage
      ? Math.ceil(pagination.totalRecords / pagination.perPage)
      : 1;
    return pagesCount;
  }

  transformDateTimeStamp(date): EpochTimeStamp {
    const tDate = dayjs(date).tz(
      JSON.parse(sessionStorage.getItem('user'))?.timezone
    );
    return tDate.valueOf();
  }

  warehouseDataRefactor(): void {
    this.warehouses?.map((warehouse: any) => {
      this.mainDataMap.set(warehouse?.id, {
        id: warehouse?.id,
        name: warehouse.name,
        roomCount: 0,
        deviceCount: 0,
        sensorCount: 0,
        buildings: new Map(),
        rooms: new Map(),
        visible: true,
        address: warehouse.address,
        createdAt: this.transformDateTimeStamp(warehouse?.createdAt),
        type: warehouse.type,
        vertices: warehouse.vertices,
        radius: warehouse.radius,
      });
      warehouse?.buildings?.map((building: any) => {
        this.mainDataMap.get(warehouse.id).buildings.set(building.id, {
          id: building.id,
          name: building.name,
          floors: new Map(),
        });
        building?.floors?.map((floor: any) => {
          this.mainDataMap
            .get(warehouse.id)
            .buildings.get(building.id)
            .floors.set(floor.id, {
              id: floor.id,
              name: floor.name,
              rooms: new Map(),
            });
        });
      });
    });
    this.visibleWarehousesCount = this.warehouses?.length;

    // console.log('warehouses data loaded successfully');
  }

  updateCount(warehouseId, key) {
    // console.log(warehouseId);
    const warehouse = this.mainDataMap.get(warehouseId);
    // console.log(warehouse);
    if (warehouse) {
      // Update the specific property
      warehouse[key]++;

      // Update the map entry
      this.mainDataMap.set(warehouseId, warehouse);
    }
  }

  getSensorMeseaurmentUnit(type): AggregatedSensorUnits {
    switch (type) {
      case 'temperature': {
        return '°C';
      }
      case 'humidity': {
        return '%';
      }
      case 'air_quality_co2': {
        return 'mg/m³';
      }
      case 'o2': {
        return '%';
      }
      default: {
        return 'mg/m³';
      }
    }
  }

  getDirectSensorMeseaurmentUnit(type): DirectSensorUnits {
    switch (type) {
      case 'in_battery_volt': {
        return 'V';
      }
      case 'rssi': {
        return 'dbm';
      }
    }
  }
  getSensorIcon(type) {
    switch (type) {
      case 'temperature': {
        return 'temperature-plus';
      }
      case 'humidity': {
        return 'humidity';
      }
      case 'air_quality_co2': {
        return 'co2';
      }
      case 'co': {
        return 'co';
      }
      case 'o2': {
        return 'o2';
      }
      case 'tpm': {
        return 'tpm';
      }
      case 'so2': {
        return 'so2';
      }
      case 'no2': {
        return 'no2';
      }
    }
  }
  getSensorModel(type, isRoom = false) {
    const sensor: AggregatedSensor = {
      id: null,
      type: type,
      count: 0,
      nACount: 0,
      offlineCount: 0,
      status: null,
      median: {
        value: null,
        intervalStatus: null,
      },
      value: null,
      values: [],
      intervals: [],
      unit: this.getSensorMeseaurmentUnit(type),
      dtt: null,
      cdt: null,
      param: null,
      icon: this.getSensorIcon(type),
      scale: 1.5,
      title: isRoom ? SENSOR_NAMES[type as string] || type : null,
      name: isRoom ? type : null,
    };
    return sensor;
  }

  getDirectSensorModel(type) {
    const sensor: DirectSensor = {
      id: null,
      type: type,
      count: 0,
      nACount: 0,
      offlineCount: 0,
      median: null,
      value: null,
      values: [],
      unit: this.getDirectSensorMeseaurmentUnit(type),
      dtt: null,
      cdt: null,
      param: null,
      icon: null,
      scale: 1.5,
      title: null,
      name: null,
    };
    return sensor;
  }

  roomsDataRefactor(): void {
    const currentTimestamp = Date.now();
    let roomsCount = 0;
    this.rooms?.map((room: any) => {
      const roomObj: Room = {
        id: room.id,
        name: room.name,
        visible: true,
        aggregatedSensorTypes: new Map(),
        devicesPerRoomCount: 0,
        aggregatedSensorsInterval: new Map<
          AggregatedSensorTypes,
          Array<RoomSensorTypeInterval>
        >(null),
        warehouseId: room.warehouse,
      };

      // this.unitsIds = this.unitsIds.concat(room?.unitIds);

      if (!Array.isArray(room.intervals)) {
        for (const [key, value] of Object.entries(room.intervals)) {
          const roomSensorIntervals: Array<RoomSensorTypeInterval> = (
            value as Array<RoomSensorTypeInterval>
          ).map((interval: any) => ({
            roomIntervals: interval.roomIntervals,
            systemNotification: interval.systemNotification,
            emailToggle: interval.emailToggle,
            dayToggle: interval.dayToggle,
            timeToggle: interval.timeToggle,
            emailNotification: interval.emailNotification,
            days: interval.days, // Initialize according to your structure
            times: interval.times, // Initialize according to your structure
            id: interval.id,
          }));

          roomObj.aggregatedSensorsInterval.set(
            key as AggregatedSensorTypes,
            roomSensorIntervals
          );
        }
      }
      // console.log(roomObj.aggregatedSensorsInterval)
      const unitsMap: Map<string, Unit> = new Map();
      this.updateCount(room?.warehouse, 'roomCount');
      roomsCount++;
      const sensorTypesSet = new Set<string>();
      const units = this.units.filter((x) => room.unitIds.includes(x.id) || []);
      units?.some((unit) => {
        if (room.id == unit.roomId) {
          unit.sensors
            .filter((x) =>
              environment.settings.aggregatedSensorTypes.includes(x.type)
            )
            .forEach((sensor) => {
              sensorTypesSet.add(sensor.type);
            });
        }

        // Optionally check if you've collected enough types or other conditions to short-circuit
        if (
          sensorTypesSet.size >=
          environment.settings.aggregatedSensorTypes.length
        ) {
          return true; // Stops the `some` loop
        }

        return false; // Continue to the next unit
      });
      const roomAvailableSensors: Array<string> = Array.from(sensorTypesSet);
      if (roomAvailableSensors.length) {
        roomAvailableSensors.forEach((availableSensor: string) => {
          let sensorType: AggregatedSensor = this.getSensorModel(
            availableSensor,
            true
          );

          const roomSensorIntervals = Object.keys(room.intervals).length
            ? room.intervals[availableSensor]
            : [];

          // console.log(roomSensorIntervals);
          if (roomSensorIntervals && roomSensorIntervals.length) {
            sensorType.intervals = roomSensorIntervals;
          }
          roomObj.aggregatedSensorTypes.set(availableSensor, sensorType);
        });
      }
      const roomUnits = this.units
        .filter((x) => room.unitIds.includes(x.id))
        .map((unit) => {
          const unitAdditionalInfo = {
            warehouseId: room.warehouse,
            buildingId: room.building,
            floorId: room.floor,
            roomId: room.id,
          };
          this.updateCount(room?.warehouse, 'deviceCount');

          /**
           * adding additional parent ids to easily access unit on sensor updates from socket
           */
          unit = { ...unit, ...unitAdditionalInfo };

          const sensorsMap: Map<string, Sensor> = new Map();
          const batterySensor = unit?.sensors?.find(
            (x) => x.type === 'in_battery_volt'
          );
          let batteryValue;
          if (batterySensor) {
            const calibrated = this.calibrateService.getSensorValue(
              batterySensor,
              {
                lastUpdate: unit?.lastUpdate,
              }
            );
            const calibratedBatteryValue =
              calibrated && calibrated?.value
                ? Number(calibrated?.value)
                : 'N/A';
            batteryValue = calibratedBatteryValue;
            // this.updateCount(room?.warehouse, 'sensorCount');
          }
          const rssiSensor = unit?.sensors?.find((x) => x.type === 'rssi');
          let rssiValue;
          if (rssiSensor) {
            const calibrated = this.calibrateService.getSensorValue(
              rssiSensor,
              {
                lastUpdate: unit?.lastUpdate,
              }
            );
            const calibratedRssiValue =
              calibrated && calibrated?.value
                ? Number(calibrated?.value)
                : 'N/A';
            rssiValue = calibratedRssiValue;
            // this.updateCount(room?.warehouse, 'sensorCount');
          }

          const unitObj: Unit = {
            id: unit.id,
            name: unit.name,
            status: 'offline',
            dtt: unit?.lastUpdate?.dtt,
            ...unitAdditionalInfo,
            aggregatedSensorTypes: new Map(),
            directSensorTypes: new Map(),
            imei: unit?.imei,
            protocol: unit?.device,
            actions: [],
          };

          // const unitAvailableSensorTypes =
          //   environment.settings.aggregatedSensorTypes.filter((x) =>
          //     unit.sensors.map((y) => y.type).includes(x)
          //   );

          const unitDirectSensorTypes =
            environment.settings.directSensorTypes.filter((x) =>
              unit.sensors.map((y) => y.type).includes(x)
            );

          unitDirectSensorTypes.length &&
            unitDirectSensorTypes?.forEach((availableSensor: any) => {
              const directSensor = this.getDirectSensorModel(availableSensor);
              if (directSensor && availableSensor == 'in_battery_volt') {
                directSensor.value = batteryValue;
                directSensor.icon = this.getBatteryIcon(batteryValue);
              } else if (directSensor && availableSensor == 'rssi') {
                directSensor.value =
                  rssiValue != 'N/A' ? Math.ceil(rssiValue) : rssiValue;
                directSensor.icon = this.getRssiIcon(rssiValue);
              }
              // const unitBatterySensor: DirectSensor = {
              //   type: availableSensor,
              //   icon: this.getBatteryIcon(batteryValue),
              //   unit: 'V',
              //   value: batteryValue,
              //   name: null,
              //   id: null,
              // };
              unitObj.directSensorTypes.set(availableSensor, directSensor);
            });
          unit.sensors
            .filter(
              (x) =>
                environment.settings.aggregatedSensorTypes.includes(x.type) ||
                environment.settings.directSensorTypes.includes(x.type)
            )
            .map((sensor) => {
              const calibrated: any = this.calibrateService.getSensorValue(
                sensor,
                {
                  lastUpdate: unit?.lastUpdate,
                }
              );

              const calibratedValue =
                (calibrated?.value || calibrated?.value) == 0
                  ? Number(calibrated?.value)
                  : null;
              const sensorObj: Sensor = {
                id: sensor.id,
                name: sensor?.name,
                param: sensor?.param,
                type: sensor?.type,
                cdt: unit?.lastUpdate?.chPrams?.[sensor?.param]?.cdt,
                dtt: unit?.lastUpdate?.dtt,
                measurementUnit:
                  sensor?.measurementUnit == 'C'
                    ? sensor?.measurementUnit?.replace('C', '°C')
                    : sensor?.measurementUnit == '℃'
                    ? sensor?.measurementUnit?.replace('℃', '°C')
                    : sensor?.measurementUnit
                    ? sensor?.measurementUnit
                    : null,
              };
              if (!isNaN(calibratedValue)) {
                if (sensor.type != 'rssi') {
                  sensorObj.value =
                    calibrated?.value !== null && calibrated?.value !== ''
                      ? Number(calibrated?.value)
                      : 'N/A';
                } else {
                  sensorObj.value =
                    (calibrated?.value || calibrated?.value == 0) &&
                    calibrated?.value != ''
                      ? Math.ceil(calibrated?.value)
                      : 'N/A';
                }
              }
              let unitSensorType: AggregatedSensor;
              if (!unitObj.aggregatedSensorTypes.has(sensorObj.id)) {
                unitSensorType = this.getSensorModel(sensorObj.type);
              }
              if (
                unitSensorType &&
                environment.settings.aggregatedSensorTypes.includes(sensor.type)
              ) {
                unitSensorType.name = sensorObj.name;
                unitSensorType.id = sensorObj.id;
                unitSensorType.cdt = sensorObj.cdt;
                unitSensorType.dtt = sensorObj.dtt;
                unitSensorType.param = sensorObj.param;
                unitSensorType.value = sensorObj.value;
                unitSensorType.status = sensorObj.status;
                unitObj.aggregatedSensorTypes.set(sensorObj.id, unitSensorType);
              }
              const directSensorType = unitObj.directSensorTypes.get(
                sensor.type
              );
              if (directSensorType) {
                directSensorType.id = sensor.id;
                directSensorType.value =
                  sensorObj.value != 'N/A' && !isNaN(sensorObj.value)
                    ? sensor.type == 'rssi'
                      ? Math.ceil(sensorObj.value)
                      : sensorObj.value
                    : 'N/A';
                directSensorType.icon =
                  sensor.type == 'rssi'
                    ? this.getRssiIcon(directSensorType.value)
                    : this.getBatteryIcon(directSensorType.value);
                directSensorType.name = sensorObj.name;
                unitObj.directSensorTypes.set(sensor.type, directSensorType);
              }

              sensorObj.status = sensorObj?.dtt
                ? currentTimestamp - sensorObj.dtt <= this.offlinePeriod
                  ? 'online'
                  : 'offline'
                : 'N/A';
              sensorObj.icon =
                sensor?.type == 'in_battery_volt'
                  ? this.getBatteryIcon(sensorObj.value)
                  : sensor?.type == 'rssi'
                  ? this.getRssiIcon(sensorObj.value)
                  : null;

              let interval: Partial<RoomInterval>;
              const sensorType = unitObj.aggregatedSensorTypes.get(sensor.id);
              if (sensorType) {
                sensorType.id = sensor.id;
                sensorType.name = sensor.name;
                sensorType.dtt = sensorObj.dtt;
                sensorType.cdt = sensorObj.cdt;
                sensorType.status = sensorObj.status;
                // sensorType.name = sensor.name;
                if (
                  sensorObj.value &&
                  roomObj.aggregatedSensorTypes.get(sensor.type).intervals
                    ?.length
                ) {
                  interval = this.getIntervalForValue(
                    sensorObj.value,
                    roomObj.aggregatedSensorTypes.get(sensor.type).intervals
                  );
                }
                sensorType.count++;
                const unitSensor = unitObj.aggregatedSensorTypes.get(sensor.id);
                if (unitSensor) {
                  const roomSensor = roomObj.aggregatedSensorTypes.get(
                    sensor.type
                  );
                  if (roomSensor) {
                    unitSensor.intervals = roomSensor.intervals;
                    // unitSensor.median.intervalStatus = interval;
                  }
                }
                unitObj.aggregatedSensorTypes.set(sensor.id, sensorType);
              }
              if (sensorObj?.value && sensorObj.status == 'online') {
                sensorObj.intervalStatus = interval;
              }
              sensorsMap.set(sensor.id, sensorObj);
              this.updateCount(room?.warehouse, 'sensorCount');
            });
          unitObj.sensors = sensorsMap;
          unitsMap.set(unit.id, unitObj);

          Array.from(roomObj.aggregatedSensorTypes.keys()).forEach((id) => {
            const sensorType = unitObj.aggregatedSensorTypes.get(id);
            if (sensorType) {
              let roomSensorType = roomObj.aggregatedSensorTypes.get(id);
              roomSensorType.count = roomSensorType.count + sensorType.count;
              roomObj.aggregatedSensorTypes.set(id, roomSensorType);
            }
          });
          return unit;
        });

      this.allRoomsUnits = [...this.allRoomsUnits, ...roomUnits];

      roomObj.units = unitsMap;

      const unitsPerRoom = this.units.filter((x) => x.roomId == roomObj.id);
      let sensorsPerRoom = 0;
      const sensorTypesCount = [
        'temperature',
        'humidity',
        'in_battery_volt',
        'rssi',
        'air_quality_co2',
        'o2',
        'so2',
        'co',
        'no2',
        'tpm',
      ];
      unitsPerRoom.forEach((unit) => {
        unit.sensors
          .filter((x) => sensorTypesCount.includes(x.type))
          .forEach((sensor) => {
            sensorsPerRoom++;
          });
      });
      roomObj.devicesPerRoomCount = sensorsPerRoom;

      const building = this.mainDataMap
        .get(room?.warehouse)
        ?.buildings?.get(room?.building);
      const floor = building?.floors?.get(room?.floor);

      roomObj.building = {
        name: building?.name,
      };

      roomObj.floor = {
        name: floor?.name,
      };
      roomObj.createdAt = room?.createdAt;
      this.mainDataMap.get(room?.warehouse)?.rooms.set(room?.id, roomObj);
    });
    this.setDataStatus();
    this.mainDataMap = this.sortRoomsInWarehouses(this.mainDataMap);
    this.mainDataMapLoaded.next(true);
    // console.log(this.mainDataMap, 'main data map')
    this.filteredDataMap = this.mainDataMap;
    this.filteredDataMap = this.setMapSortOrder(
      this.filteredDataMap,
      'asc',
      'createdAt'
    );

    // console.log('rooms and units data loaded successfully');
  }

  sortRoomsInWarehouses(
    warehouses: Map<string, Warehouse>
  ): Map<string, Warehouse> {
    warehouses.forEach((warehouse: Warehouse) => {
      // Convert rooms map to array of [roomKey, roomValue] entries
      const sortedRoomsArray = this.setMapSortOrder(
        warehouse.rooms,
        'asc',
        'createdAt'
      );

      // Convert sorted array back to Map
      warehouse.rooms = new Map(sortedRoomsArray);
    });

    return warehouses; // Return the updated warehouses map (optional)
  }

  /**
   * get interval for value
   * @param value
   * @param intervals
   */
  getIntervalForValue(
    value: number | string,
    intervals: any[]
  ): Partial<any> | null {
    if (!intervals.length || value === 'N/A') return null;

    const inter = intervals.flatMap((x) => x.roomIntervals);

    if (inter.length) {
      for (const interval of inter) {
        const from = interval.from ?? -Infinity; // Interpret null as -Infinity
        const to = interval.to ?? Infinity; // Interpret null as +Infinity

        if (value >= from && value <= to) {
          return interval;
        }
      }
    }

    // If the value is not within any interval, return null
    return null;
  }

  /**
   * simple array is array and array.length because api is unstable type
   */
  validateArrayTypeAndLength(array: Array<any>) {
    return Array.isArray(array) && array?.length;
  }

  refactorInterval(interval) {
    return {
      from: interval?.from,
      to: interval?.to,
      safeInterval: interval?.safeInterval,
      color: interval.type == 'pre-alert' ? '#ffc107' : '#dc3545',
    };
  }

  getWarehouses(page: number = 1) {
    return this.httpService.get(
      `warehouses`,
      {
        page: page,
        perPage: 1000,
      },
      true
    );
  }

  getRooms(page: number = 1) {
    return this.httpService.get(
      `rooms`,
      {
        page: page,
        perPage: 1000,
      },
      true
    );
  }

  getUnits(page: number = 1) {
    return this.httpService.get(
      `units`,
      {
        page: page,
        perPage: 1000,
      },
      true
    );
  }

  /**
   * Sets the status of warehouses,rooms,units based on the latest timestamp within sensors.
   * @remarks
   * This function iterates over the provided warehouses map, checking the latest timestamp
   * within sensors of each unit. If the latest timestamp is within the last global offline period,
   * the item is marked as online.
   */
  setDataStatus(): void {
    for (const [warehouseKey, warehouse] of this.mainDataMap.entries()) {
      this.updateWarehouseStatus(warehouse);

      // loop over warehouse rooms and return online units to activate a timer for them
      Array.from(warehouse?.rooms?.values())
        .filter((room) => room?.status === 'online')
        .map((room: Room) => {
          Array.from(room.units.values()).map((unit: Unit) => {
            this.setUnitTimer(warehouse, unit);
          });
        });
    }
  }

  updateWarehouseStatus(warehouse: Warehouse) {
    const currentTimestamp = Date.now();

    /**
     * The latest timestamp within units for the current warehouse.
     * @type {number}
     */
    let warehouseLatestTimestamp = 0;

    // Iterate over buildings in the warehouse
    // for (const [buildingKey, building] of warehouse.buildings.entries()) {
    // Iterate over floors in the warehouse
    // for (const [floorKey, floor] of building.floors.entries()) {
    // Iterate over rooms in the warehouse
    for (const [roomKey, room] of warehouse?.rooms?.entries()) {
      let roomLatestTimestamp = 0;
      // Iterate over units in the room

      let offlineUnitCount: number = 0;
      let nAUnitCount: number = 0;

      Array.from(room.aggregatedSensorTypes.keys()).forEach((type) => {
        const roomSensor = room.aggregatedSensorTypes.get(type);
        if (roomSensor) {
          roomSensor.values = [];
          roomSensor.nACount = 0;
          roomSensor.offlineCount = 0;
          room.aggregatedSensorTypes.set(type, roomSensor);
        }
      });

      for (const [unitKey, unit] of room.units.entries()) {
        let unitLatestTimestamp = 0;
        // Iterate over sensors in the unit

        /**
         * for when calculating unit timestamp from sensors later
         */
        // unitLatestTimestamp = Math.max(
        //   unitLatestTimestamp,
        //   unit?.timeStamp ? unit?.timeStamp : 0
        // );

        unitLatestTimestamp = unit?.dtt ? unit?.dtt : 0;

        roomLatestTimestamp = Math.max(
          roomLatestTimestamp,
          unit?.dtt ? unit?.dtt : 0
        );

        warehouseLatestTimestamp = Math.max(
          warehouseLatestTimestamp,
          unit?.dtt ? unit?.dtt : 0
        );

        const isUnitOnline =
          currentTimestamp - unitLatestTimestamp < this.offlinePeriod;
        unit.status = isUnitOnline
          ? 'online'
          : !isUnitOnline && unit?.dtt
          ? 'offline'
          : 'N/A';
        if (room.id == unit.roomId) {
          for (const [sensorKey, sensor] of unit.sensors.entries()) {
            // let sensorLatestTimestamp = 0;
            // Update the latest timestamp if the sensor's timestamp is greater

            // sensorLatestTimestamp = Math.max(
            //   sensorLatestTimestamp,
            //   sensor?.timeStamp
            // );

            const isSensorOnline =
              currentTimestamp - sensor?.dtt < this.offlinePeriod;

            sensor.status = isSensorOnline
              ? 'online'
              : !isSensorOnline && sensor?.dtt && sensor.cdt
              ? 'offline'
              : 'N/A';
            // Array.from(unit.aggregatedSensorTypes.keys()).forEach((id) => {
            const unitSensorType = unit.aggregatedSensorTypes.get(sensor.id);
            if (unitSensorType && unit.roomId == room.id) {
              unitSensorType.offlineCount = 0;
              unitSensorType.nACount = 0;
              unitSensorType.values = [];
              const isSensorOnline =
                currentTimestamp - unitSensorType?.dtt < this.offlinePeriod;
              unitSensorType.status = isSensorOnline
                ? 'online'
                : !isSensorOnline && sensor?.dtt && sensor.cdt
                ? 'offline'
                : 'N/A';
              if (
                sensor.value != 'N/A' &&
                !isNaN(sensor.value) &&
                isUnitOnline &&
                sensor?.dtt
              ) {
                unitSensorType.values.push(sensor.value);
              }

              if (!isSensorOnline && sensor?.dtt) {
                unitSensorType.offlineCount++;
              } else if (!isSensorOnline && !sensor?.dtt) {
                unitSensorType.nACount++;
              }

              unit.aggregatedSensorTypes.set(unitSensorType.id, unitSensorType);
            }
            // });

            this.valueChangeState[sensorKey] = 'initial';
          }
          if (
            unit.aggregatedSensorTypes &&
            unit.aggregatedSensorTypes.size > 0
          ) {
            unit.offlineSensorCount = Array.from(
              unit.aggregatedSensorTypes.values()
            ).reduce(
              (total, sensorType) => total + (sensorType.offlineCount || 0),
              0
            );

            unit.nASensorCount = Array.from(
              unit.aggregatedSensorTypes.values()
            ).reduce(
              (total, sensorType) => total + (sensorType.nACount || 0),
              0
            );
          }

          Array.from(unit.aggregatedSensorTypes.keys()).forEach((id) => {
            const unitSensorType = unit.aggregatedSensorTypes.get(id);
            const unitType = unitSensorType.type;
            const roomSensorType = room.aggregatedSensorTypes.get(unitType);
            if (roomSensorType && unitSensorType && unit.roomId == room.id) {
              roomSensorType.values = [
                ...roomSensorType.values,
                ...(unitSensorType?.values || []),
              ];
              if (unitSensorType.status == 'N/A') {
                roomSensorType.nACount++;
              } else if (unitSensorType.status == 'offline') {
                roomSensorType.offlineCount++;
              }

              unitSensorType.median = this.setMedian(
                unitSensorType.values,
                roomSensorType.intervals,
                unitSensorType.count,
                unitSensorType.offlineCount,
                unitSensorType.nACount,
                unitSensorType.status
              );
              roomSensorType.dtt = unitSensorType.dtt;

              // roomSensorType.status = unitSensorType.status;
              unit.aggregatedSensorTypes.set(id, unitSensorType);
              room.aggregatedSensorTypes.set(
                roomSensorType.type,
                roomSensorType
              );
            }
          });

          Array.from(room.aggregatedSensorTypes.keys()).forEach(
            (roomSensorType) => {
              const roomSensor = room.aggregatedSensorTypes.get(roomSensorType);
              if (roomSensor) {
                let allNA = true;
                let allOffline = false;
                let anyOnline = false;

                Array.from(room.units.values()).forEach((unit) => {
                  const unitSensors = Array.from(
                    unit.aggregatedSensorTypes.values()
                  );
                  const matchingSensors = unitSensors.filter(
                    (sensor) => sensor.type === roomSensorType
                  );

                  if (
                    matchingSensors.some((sensor) => sensor.status !== 'N/A')
                  ) {
                    allNA = false;
                  }
                  if (
                    matchingSensors.some(
                      (sensor) =>
                        sensor.status != 'N/A' && sensor.status != 'online'
                    )
                  ) {
                    allOffline = true;
                  }
                  if (
                    matchingSensors.some((sensor) => sensor.status === 'online')
                  ) {
                    anyOnline = true;
                  }
                });

                if (anyOnline) {
                  roomSensor.status = 'online';
                } else if (allOffline) {
                  roomSensor.status = 'offline';
                } else if (allNA) {
                  roomSensor.status = 'N/A';
                }

                room.aggregatedSensorTypes.set(roomSensorType, roomSensor);
              }
            }
          );
          const sensorsArray = Array.from(unit.aggregatedSensorTypes.values());
          const filteredSensors = sensorsArray.sort((a, b) => {
            const order = [
              'temperature',
              'humidity',
              'air_Quality_co2',
              'no2',
              'o2', // Ensure consistent casing
              'co',
              'so2',
              'tpm',
            ];

            // Normalize the sensor types to lowercase for comparison
            const normalizeType = (type: string) => type.toLowerCase();

            // Get the normalized type of each sensor
            const typeA = normalizeType(a.type);
            const typeB = normalizeType(b.type);

            // Get the index for each type based on the order array (also normalized)
            const priorityA = order.findIndex(
              (o) => normalizeType(o) === typeA
            );
            const priorityB = order.findIndex(
              (o) => normalizeType(o) === typeB
            );

            // If the type is not found in the order array, set a default index
            const defaultIndex = order.length;

            // Use defaultIndex for types that aren't found in the order
            return (
              (priorityA !== -1 ? priorityA : defaultIndex) -
              (priorityB !== -1 ? priorityB : defaultIndex)
            );
          });
          const groupedSensors: AggregatedSensor[][] = [];
          for (let i = 0; i < filteredSensors.length; i += 4) {
            groupedSensors.push(filteredSensors.slice(i, i + 4));
          }

          // Add groupedSensors to the unit
          unit.groupedSensors = groupedSensors;
          this.valueChangeState[unitKey] = 'initial';

          Array.from(room.aggregatedSensorTypes.keys()).forEach((type) => {
            const roomSensorType = room.aggregatedSensorTypes.get(type);
            if (roomSensorType) {
              roomSensorType.median = this.setMedian(
                roomSensorType.values,
                room.aggregatedSensorTypes.get(type).intervals
                  ? room.aggregatedSensorTypes.get(type).intervals
                  : null,
                roomSensorType.count,
                roomSensorType.offlineCount,
                roomSensorType.nACount,
                roomSensorType.status
              );
              room.aggregatedSensorTypes.set(type, roomSensorType);
            }
          });
        }
        room.timeStamp = roomLatestTimestamp;
        const isRoomOnline =
          currentTimestamp - roomLatestTimestamp < this.offlinePeriod;
        room.status = isRoomOnline ? 'online' : 'offline';

        // room.offlineUnitCount = Array.from(
        //   room.aggregatedSensorTypes.values()
        // ).filter(
        //   (x) => x.offlineCount > 0
        //   // Array.from(x.aggregatedSensorTypes.values()).filter(
        //   //   (x) => x.status == 'offline'
        //   // )
        // ).length;

        // room.nAUnitCount = Array.from(room.aggregatedSensorTypes.values()).filter(
        //   (x) => x.nACount > 0
        // ).length;

        // if (
        //   (room.status === 'online' &&
        //     (room.offlineUnitCount || room.nAUnitCount)) ||
        //   (room?.aggregatedSensorTypes.get('temperature')?.median
        //     .intervalStatus &&
        //     !room?.aggregatedSensorTypes.get('temperature')?.median
        //       .intervalStatus?.safeInterval) ||
        //   (room?.aggregatedSensorTypes.get('humidity')?.median.intervalStatus &&
        //     !room?.aggregatedSensorTypes.get('humidity')?.median.intervalStatus
        //       ?.safeInterval)
        // ) {
        //   room.warning = true;
        // } else {
        //   room.warning = false;
        // }
        if (room.status !== 'online' || room.warning) {
          room.alertTimeStamp = roomLatestTimestamp;
        }
        this.valueChangeState[roomKey] = 'initial';

        // room.offlineUnitCount = offlineUnitCount;
        if (room.aggregatedSensorTypes && room.aggregatedSensorTypes.size > 0) {
          room.offlineUnitCount = Array.from(room.units.values()).filter(
            (unit) => {
              // Track unique offline types
              const typeOfflineStatus = new Set();

              // Check each sensor in the unit's aggregatedSensorTypes
              Array.from(unit.aggregatedSensorTypes.values()).forEach(
                (sensor) => {
                  // If the sensor is offline, add its type to the set
                  if (sensor.offlineCount > 0) {
                    typeOfflineStatus.add(sensor.type);
                  }
                }
              );

              // If we have any types marked offline in this unit, count it as an offline unit
              return typeOfflineStatus.size > 0;
            }
          ).length;
          room.nAUnitCount = Array.from(room.units.values()).filter((unit) => {
            // Track unique offline types
            const typeOfflineStatus = new Set();

            // Check each sensor in the unit's aggregatedSensorTypes
            Array.from(unit.aggregatedSensorTypes.values()).forEach(
              (sensor) => {
                // If the sensor is offline, add its type to the set
                if (sensor.nACount > 0) {
                  typeOfflineStatus.add(sensor.type);
                }
              }
            );

            // If we have any types marked offline in this unit, count it as an offline unit
            return typeOfflineStatus.size > 0;
          }).length;
        }
      }
    }
    const roomCountOffline = Array.from(warehouse.rooms.values()).filter(
      (x) => x.offlineUnitCount && x.offlineUnitCount > 0
    ).length;
    warehouse.offlineRoomCount = roomCountOffline;

    const roomCountNA = Array.from(warehouse.rooms.values()).filter(
      (room) => room.nAUnitCount && room.nAUnitCount > 0
    ).length;
    warehouse.nARoomCount = roomCountNA;

    warehouse.visibleRoomsCount = Array.from(warehouse.rooms.values()).filter(
      (x) => x.visible
    ).length;

    warehouse.timeStamp = warehouseLatestTimestamp;

    const isWarehouseOnline =
      currentTimestamp - warehouseLatestTimestamp < this.offlinePeriod;
    warehouse.status = isWarehouseOnline ? 'online' : 'offline';

    /**
     * set warehouse warnings
     * if any room have warning or if any room is not online aka all units are either offline or n/a
     */
    const roomsWarning = Array.from(warehouse.rooms.values()).some(
      (x) => x.warning
    );
    const roomsOffline = Array.from(warehouse.rooms.values()).some(
      (x) => x.status !== 'online'
    );
    warehouse.warning = roomsWarning || roomsOffline;

    const currentTimestampAfter = Date.now();

    // console.log(
    //   'Benchmarking Performance of nested loops operations over real data ',
    //   currentTimestampAfter - currentTimestamp
    // );

    this.valueChangeState[warehouse.id] = 'initial';

    // console.log('warehouse',warehouse);
  }

  setMapVisibility(originalMap: Map<string, any>, searchModel: string): void {
    if (searchModel) {
      Array.from(originalMap.entries()).forEach((x, i) => {
        const name = x[1]?.name.toLowerCase();
        const searchKeyword = searchModel.toLowerCase();
        if (name.includes(searchKeyword)) {
          x[1].visible = true;
        } else {
          x[1].visible = false;
        }
        return x;
      });
    } else {
      Array.from(originalMap.entries()).forEach((x) => {
        x[1].visible = true;
      });
    }
  }

  setMapSortOrder(
    originalMap: Map<string, any>,
    sortOrder: any = '',
    key: any // Default to 'name' if key is not provided
  ): Map<string, any> {
    const sortedArray: [string, any][] = Array.from(originalMap.entries())
      .sort((a, b) => {
        let valueA: any = a[1][key];
        let valueB: any = b[1][key];
        if (valueA == null && valueB == null) {
          return 0;
        } else if (valueA == null) {
          return sortOrder === 'asc' ? -1 : 1;
        } else if (valueB == null) {
          return sortOrder === 'asc' ? 1 : -1;
        }
        if (key === 'name') {
          valueA = String(valueA).toLowerCase();
          valueB = String(valueB).toLowerCase();
        }

        // Perform the comparison based on the specified sortOrder
        if (sortOrder === 'asc') {
          return valueA < valueB ? -1 : 1;
        } else {
          return valueA > valueB ? -1 : 1;
        }
      })
      .map((x, i) => {
        x[1].order = i + 1;
        return x;
      });

    return new Map(sortedArray);
  }

  setMedian(
    values: Array<number>,
    intervals: any[],
    totalCount?: number,
    offlineCount?: number,
    nACount?: number,
    status?: string
  ): Median {
    let value = this.calculateMedian(values.map((x) => Number(x)));
    const offline =
      totalCount === 0 || (offlineCount > 0 && totalCount === offlineCount);
    const nA = totalCount === 0 || (nACount > 0 && totalCount === nACount);

    value = !isNaN(value) ? roundDownDecimals(value) : null;
    if (!value && nA && status == 'N/A') {
      value = 'N/A';
    } else if (!value && offline && status == 'offline') {
      value = '-';
    } else if (!value && !offline && !nA && status == 'N/A') {
      value = 'N/A';
    } else if (!value && !offline && !nA && status == 'offline') {
      value = '-';
    }
    const interval =
      !isNaN(value) &&
      this.getIntervalForValue(value, intervals ? intervals : []);
    return {
      value: value,
      intervalStatus: interval,
    };
  }

  /**
   * Calculates the median of an array of numbers.
   *
   * @param {number[]} numbers - The array of numbers for which to calculate the median.
   * @returns {number} The median value of the input array.
   *
   * @example
   * const median = calculateMedian([1, 2, 3, 4, 5]);
   * console.log(median); // Output: 3
   *
   * @example
   * const medianEven = calculateMedian([1, 2, 3, 4]);
   * console.log(medianEven); // Output: 2.5
   */
  calculateMedian(numbers) {
    const sortedNumbers = numbers.slice().sort((a, b) => a - b);
    const middle = Math.floor(sortedNumbers.length / 2);

    if (sortedNumbers.length % 2 === 0) {
      // If the array has an even number of elements, return the average of the two middle values.
      return (sortedNumbers[middle - 1] + sortedNumbers[middle]) / 2;
    } else {
      // If the array has an odd number of elements, return the middle value.
      return sortedNumbers[middle];
    }
  }

  getUnitSensorByParam(unit: any, param: string) {
    return unit?.sensors?.filter((x) => x.param === param);
  }
  getSensorById(sensorId) {
    // Loop through each unit in the units array
    const unitSensors = this.allRoomsUnits.flatMap((x) => x.sensors);
    return unitSensors.find((x) => x.id == sensorId);

    // If no matching sensor is found, return undefined
    // return undefined;
  }

  pushSocketUpdates(socketMessage: SocketMessageBody | any) {
    const socketUnit = this.allRoomsUnits.find(
      (x) => x.id === socketMessage.id
    );
    const params: SocketMessageParam = socketMessage?.d && socketMessage?.d[10];
    const dtt = socketMessage?.d[1];
    const warehouse = this.mainDataMap.get(socketUnit?.warehouseId);
    const room = warehouse?.rooms.get(socketUnit?.roomId);
    let unit: Unit = room?.units.get(socketUnit?.id);
    if (dtt && unit) {
      if (params) {
        for (const [paramKey, param] of Object.entries(params)) {
          const allSocketSensor = this.getUnitSensorByParam(
            socketUnit,
            paramKey
          );
          if (room && socketUnit) {
            if (allSocketSensor.length > 0) {
              allSocketSensor.forEach((socketSensor) => {
                let sensor: Sensor = unit?.sensors.get(socketSensor?.id);
                // console.log(sensor.name, param.v)
                if (
                  ((param.v != '' && param.v != undefined) || param.v == 0) &&
                  sensor &&
                  sensor.id == socketSensor.id
                ) {
                  let interval: Partial<RoomInterval>;

                  const calibrated: any = isNaN(Number(param.v))
                    ? { value: param.v }
                    : this.calibrateService.getSensorValue(socketSensor, {
                        value: Number(param.v),
                      });

                  let calibratedValue;
                  if (typeof calibrated.value != 'string') {
                    calibratedValue =
                      calibrated &&
                      (calibrated?.value == 0 || calibrated?.value != '')
                        ? Number(calibrated?.value)
                        : NaN;
                  } else {
                    if (isNaN(Number(calibrated.value)))
                      calibratedValue = 'N/A';
                  }

                  interval = this.getIntervalForValue(
                    Number(calibratedValue),
                    room.aggregatedSensorTypes.get(sensor.type)?.intervals
                      ? room.aggregatedSensorTypes.get(sensor.type)?.intervals
                      : []
                  );
                  if (sensor.type != 'rssi') {
                    sensor.value =
                      calibratedValue || calibratedValue == 0
                        ? calibratedValue
                        : 'N/A';
                  } else {
                    sensor.value =
                      calibratedValue == 0 || !isNaN(Number(calibratedValue))
                        ? Math.trunc(calibratedValue)
                        : 'N/A';
                  }
                  if (
                    !environment.settings.aggregatedSensorTypes.includes(
                      sensor.type
                    )
                  ) {
                    Array.from(unit.directSensorTypes.keys()).forEach(
                      (type) => {
                        const sensors = Array.from(
                          unit.sensors.values()
                        ).filter((x) =>
                          environment.settings.directSensorTypes.includes(
                            x.type
                          )
                        );

                        if (type === 'in_battery_volt') {
                          const sensor = sensors.find(
                            (x) => x.type === 'in_battery_volt'
                          );
                          if (sensor) {
                            const unitBatterySensor =
                              unit.directSensorTypes.get(type);
                            unitBatterySensor.value = sensor.value;
                            unitBatterySensor.icon = this.getBatteryIcon(
                              sensor.value
                            );
                            unit.directSensorTypes.set(type, unitBatterySensor);
                          }
                        } else if (type === 'rssi') {
                          const sensor = sensors.find((x) => x.type === 'rssi');
                          if (sensor) {
                            const unitRssiSensor =
                              unit.directSensorTypes.get(type);
                            unitRssiSensor.value =
                              sensor.value == 'N/A' &&
                              typeof sensor.value == 'string'
                                ? 'N/A'
                                : Math.trunc(sensor.value);
                            unitRssiSensor.icon = this.getRssiIcon(
                              unitRssiSensor.value
                            );
                            unit.directSensorTypes.set(type, unitRssiSensor);
                          }
                        }
                      }
                    );
                  }
                  if (!isNaN(calibratedValue)) {
                    if (
                      !environment.settings.directSensorTypes.includes(
                        sensor.type
                      )
                    ) {
                      Array.from(unit.sensors.values())
                        .filter((x) =>
                          environment.settings.aggregatedSensorTypes.includes(
                            x.type
                          )
                        )
                        .forEach((sensor) => {
                          const unitSensorType = unit.aggregatedSensorTypes.get(
                            sensor.id
                          );
                          if (
                            unitSensorType &&
                            unitSensorType.param == paramKey &&
                            unitSensorType.id == sensor.id
                          ) {
                            unitSensorType.cdt = param.cdt;
                            unitSensorType.dtt = dtt;
                            unitSensorType.value =
                              calibratedValue || calibratedValue == 0
                                ? calibratedValue
                                : 'N/A';
                            unit.aggregatedSensorTypes.set(
                              sensor.id,
                              unitSensorType
                            );
                          }
                        });
                    }
                  }
                  if (sensor.type == 'in_battery_volt') {
                    sensor.icon = this.getBatteryIcon(sensor.value);
                  }
                  if (sensor.type == 'rssi') {
                    let value =
                      sensor.value == 'N/A' && typeof sensor.value == 'string'
                        ? 'N/A'
                        : Math.trunc(sensor.value);
                    sensor.icon = this.getRssiIcon(value);
                  }

                  sensor.cdt = param.cdt;
                  sensor.dtt = dtt;
                  sensor.intervalStatus = interval;
                  if (dtt) {
                    unit.dtt = dtt;
                  }
                }
                if (
                  sensor &&
                  !environment.settings.directSensorTypes.includes(sensor.type)
                ) {
                  Array.from(unit.sensors.values())
                    .filter((x) =>
                      environment.settings.aggregatedSensorTypes.includes(
                        x.type
                      )
                    )
                    .forEach((sensor) => {
                      if (paramKey == sensor.param) {
                        this.triggerAnimation(sensor?.id, 500);
                      }
                    });
                }
              });
              // this.triggerAnimation(socketSensor?.id, 500);
            }
            // if (dtt) {
            //   unit.dtt = dtt;
            // }
          }
        }
      }

      if (socketUnit?.id && socketUnit.roomId && socketUnit.warehouseId) {
        this.triggerAnimation(socketUnit.id, 500).then(() => {
          this.triggerAnimation(socketUnit.roomId, 300).then(() => {
            this.triggerAnimation(socketUnit.warehouseId, 200).then(() => {
              this.updateWarehouseStatus(warehouse);
              this.setUnitTimer(warehouse, unit);
              this.socketUpdate.next(unit);
              this.latestSocketMessageUpdate.next(socketMessage);
            });
          });
        });
      }
    }
  }

  /**
   * Sets a timer to trigger the execution of `updateWarehouseStatus` after a specified countdown time,
   * ensuring proper timer management and recurring execution.
   */
  setUnitTimer(warehouse: Warehouse, unit?: Unit) {
    if (unit.dtt > 0) {
      const currentTimestamp = Date.now();
      const countdownTime = this.offlinePeriod - (currentTimestamp - unit.dtt);
      if (countdownTime > 0) {
        // console.log('setItemTimer', warehouse.name);
        // Unsubscribe from any existing timer to prevent multiple subscriptions
        unit?.updateTimer?.unsubscribe();
        unit.updateTimer = timer(countdownTime).subscribe(() => {
          // console.log(`executed delayed update on warehouse ${warehouse.name} after  ${countdownTime/1000} seconds`);
          this.updateWarehouseStatus(warehouse);
        });
      }
    }
  }

  async triggerAnimation(key: string, duration: number): Promise<void> {
    return new Promise<void>((resolve) => {
      this.valueChangeState[key] = 'changing';
      setTimeout(() => {
        this.valueChangeState[key] = 'initial';
        resolve();
      }, duration);
    });
  }

  onSortChange(sortBy, map: Map<string, any>, selectedSort) {
    this.sortModel = this.sortTypes(sortBy);
    return this.setMapSortOrder(
      map,
      this.sortModel.appliedSort,
      this.sortModel.sortKey
    );
  }

  sortTypes(selectedSortType) {
    let sortModel = {
      appliedSort: '',
      sortKey: '',
    };
    switch (selectedSortType) {
      case 'nameAsc': {
        sortModel = {
          appliedSort: 'asc',
          sortKey: 'name',
        };
        break;
      }
      case 'nameDesc': {
        sortModel = {
          appliedSort: 'desc',
          sortKey: 'name',
        };
        break;
      }
      case 'createdAtAsc': {
        sortModel = {
          appliedSort: 'asc',
          sortKey: 'createdAt',
        };
        break;
      }
      case 'createdAtDesc': {
        sortModel = {
          appliedSort: 'desc',
          sortKey: 'createdAt',
        };
        break;
      }
      case 'alertDesc': {
        sortModel = {
          appliedSort: 'desc',
          sortKey: 'alertTimeStamp',
        };
        break;
      }
    }
    return sortModel;
  }

  getBatteryIcon(batteryValue: number | 'N/A'): string {
    let icon = 'battery-NA';
    if (batteryValue === 'N/A' || isNaN(batteryValue)) return icon;
    if (batteryValue >= 3.4) {
      icon = 'battery-100';
    } else if (batteryValue >= 3.1 && batteryValue < 3.4) {
      icon = 'battery-70';
    } else if (batteryValue >= 2.9 && batteryValue < 3.1) {
      icon = 'battery-50';
    } else if (batteryValue > 0 && batteryValue < 2.9) {
      icon = 'battery-20';
    } else if (batteryValue == 0) {
      icon = 'battery-0';
    }
    return icon;
  }
  getRssiIcon(rssiValue: number | string): string {
    let icon = 'Status=NA';

    if (rssiValue === 'N/A' || typeof rssiValue == 'string' || isNaN(rssiValue))
      return icon;
    // Parse rssiValue to a number if it is not already
    let parsedRssiValue;
    if (typeof rssiValue == 'number') {
      parsedRssiValue = Math.trunc(rssiValue);
    } else {
      parsedRssiValue = rssiValue;
    }
    if (parsedRssiValue > 0) {
      icon = 'Status=WeakSignal';
    } else if (parsedRssiValue >= -50) {
      icon = 'Status=Excellent';
    } else if (parsedRssiValue >= -80 && parsedRssiValue < -50) {
      icon = 'Status=Good';
    } else if (parsedRssiValue >= -100 && parsedRssiValue < -80) {
      icon = 'Status=Fair';
    } else if (parsedRssiValue >= -130 && parsedRssiValue < -100) {
      icon = 'Status=Poor';
    } else if (parsedRssiValue < -130) {
      icon = 'Status=WeakSignal';
    }

    return icon;
  }

  showConfirmationDialog(
    message: string,
    header: string,
    acceptLabel: string,
    rejectLabel: string,
    acceptIcon: string,
    rejectIcon: string,
    acceptButtonStyleClass: string,
    rejectButtonStyleClass: string,
    onAccept: () => void,
    onReject?: () => void
  ): void {
    this.confirmationService.confirm({
      message: message,
      header: header,
      acceptLabel: acceptLabel,
      rejectLabel: rejectLabel,
      acceptIcon: acceptIcon,
      rejectIcon: rejectIcon,
      acceptButtonStyleClass: acceptButtonStyleClass,
      rejectButtonStyleClass: rejectButtonStyleClass,
      accept: onAccept,
      reject: onReject || (() => {}),
    });
  }

  updateSensorIntervalsForUnits(
    room: Room,
    sensorType: string,
    sensorIntervals: any[],
    warehouse
  ): void {
    Array.from(room.units.values()).forEach((unit) => {
      const unitAggregatedSensors = Array.from(
        unit.aggregatedSensorTypes.values()
      ).filter((x) => x.type.toLowerCase() === sensorType.toLowerCase());

      if (unitAggregatedSensors.length) {
        unitAggregatedSensors.forEach((unitAggregatedSensor) => {
          unitAggregatedSensor.intervals = sensorIntervals;
          unit.aggregatedSensorTypes.set(
            unitAggregatedSensor.id,
            unitAggregatedSensor
          );
          const sensorIds = [];
          if (unit.sensors.size) {
            for (const [key, value] of unit.sensors.entries()) {
              if (unitAggregatedSensor.type == value.type) {
                sensorIds.push(value.id);
              }
            }

            if (sensorIds.length) {
              sensorIds.forEach((sensorId) => {
                const sensor = unit.sensors.get(sensorId);
                if (sensor) {
                  sensor.intervalStatus = this.getIntervalForValue(
                    sensor.value,
                    sensorIntervals
                  );
                  unit.sensors.set(sensorId, sensor);
                }
              });
            }
          }
          room.units.set(unit.id, unit);
        });
      }
    });

    if (warehouse) {
      warehouse.rooms.set(room.id, room);
      this.mainDataMap.set(warehouse.id, warehouse);
      this.updateWarehouseStatus(warehouse);
      this.filteredDataMap.set(warehouse.id, warehouse);
    }
  }

  showError(message: any) {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: message,
    });
  }
}
