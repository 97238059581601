export enum SensorType {
  CO = 'co',
  O2 = 'o2',
  NO2 = 'no2',
  SO2 = 'so2',
  TPM = 'tpm',
  CO2 = 'air_quality_co2',
  TEMPERATURE = 'temperature',
  HUMIDITY = 'humidity',
}

export const SENSOR_NAMES: Record<SensorType, string> = {
  [SensorType.CO]: 'CO',
  [SensorType.O2]: 'O2',
  [SensorType.NO2]: 'NO2',
  [SensorType.SO2]: 'SO2',
  [SensorType.TPM]: 'TPM',
  [SensorType.CO2]: 'CO2',
  [SensorType.TEMPERATURE]: 'Temperature',
  [SensorType.HUMIDITY]: 'Humidity',
};
